body {
  padding: 0;
  color: #242527;
  background-color: #ffffff;
  font-family: 'SF UI Text', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a,
svg,
input,
textarea,
button,
[contenteditable='true'] {
  touch-action: manipulation;
}
